import React from 'react';
import { FileuploadCommon } from '../common/FileUpload';
import { MasterSelect } from '../common/MasterSelect';

export const TicketInvetoryUpload: React.FC = () => (
  <>
  
  <MasterSelect type='Trip Ticket Inventory' inlineSize={350} />
  <FileuploadCommon componentName={'inventory-upload'}/>
  </>
);
