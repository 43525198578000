import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Select, Space, Drawer, Table, Popover } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Radio } from 'antd';
import {
    ReloadOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterTypeSelectorValue } from './slice-master';







export const MasterSelect = (props:any) => {
    const { type, inlineSize, onChange, defaultOpen } = props;
    const { masterSelectValuesLoading, masterSelectValues } = useSelector((state: any) => state.master)
    const options = (masterSelectValues[type] || []).map((e: any) => ({ label: e.masterValue, value: e.id}))
    const loading = masterSelectValuesLoading[type]
    const dispatch = useDispatch();    
    useEffect(()=>{
        dispatch(getMasterTypeSelectorValue(type));
    },[])
    return <Select {...props} 
        style={{ inlinesize: inlineSize }}
        autoFocus={true}
        loading={loading} options={options} 
              placeholder={'Select '+ type} />;
}
