import { Result } from 'antd';
import React, {  } from 'react';
import { MasterSelect } from '../common/MasterSelect';
import { useSelector } from 'react-redux';

export const preAuthzFn = (allowedRoles:string, userRole:string) => {
    const all = allowedRoles.split(',');
    if (userRole === undefined) {
        return false
    }
    const scopes = userRole.split(",");
    for (let role of all) {
        if (scopes.indexOf(role) >= 0) {
            return true
        }
    }

    return false
};

export const PreAuthz = ({ allowedRoles, children, hideResult }: { allowedRoles: string; children: any, hideResult?:boolean}) => {
    const all = allowedRoles.split(',');
    const { userinfo, data } = useSelector((state: any) => state.login)


    if (userinfo === undefined) {
        return <>Loading</>
    }
    const scopes = userinfo.userRole.split(",");
    for (let role of all) {
        if (scopes.indexOf(role) >=0 ) {
         return <>
                {children}
            </>
        }
    }

    if (hideResult) {
        return null
    }

    return <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."        
    />


};

