import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Flex, Form, Input, Popconfirm, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlusOutlined
} from '@ant-design/icons';
import { saveOrUpdateMasterValues } from './slice-master';
import { DataTable } from './DataTable';

interface Item {
  id: number;
  masterValue: string;
  active: boolean;
  comment: string | undefined | null
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'boolean' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const MasterItems = (props: any) => {
  const { loading, masterTypeValues } = useSelector((state: any) => state.master);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState(0);

  const isEditing = (record: Item) => record.id === editingKey;

  const edit = (record: Item) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (item: Item) => {
    const values = form.getFieldsValue();
    let inputs = [];
    inputs.push(
      {
        ...values,
        id: item.id
      }
    )
    dispatch(saveOrUpdateMasterValues(inputs));
    setEditingKey(0);
  };

  const ActiveComponent = (props: any) => {
    return <Checkbox {...props} checked={props.value} onChange={() => {
      props.onChange(!props.value)
    }} />
  }
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'boolean' ? <ActiveComponent /> : <Input autoComplete='off' />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: dataIndex === 'comment' ? false : true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: 'Master Value',
      dataIndex: 'masterValue',
      width: '45%',
      editable: true,
      key: 'masterValue',
      filteredValue: [props.searchText],
      onFilter: (value: any, record: any) => {
        return String(record.masterValue)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.comment)
            .toLowerCase()
            .includes(value.toLowerCase());

      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '10%',
      editable: true,
      key: 'active',
      render: (_: any, record: Item) => <Checkbox checked={record.active} ></Checkbox>
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '35%',
      editable: true,
      key: 'comment'
    },
    {
      title: 'Operations',
      dataIndex: '',
      width: '10%',
      key: 'x',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);

        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== 0} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'active' ? 'boolean' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <DataTable
        extra={props.extra}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        onPaginationChange={cancel}
        rowClassName="editable-row"
        title="Master Type"
        ref={props.datatableref}
        key="id" columns={mergedColumns}
        componentuniquekey="mastertyepevalues"
        buildApiUrl={(pagenuber, pagesize) => `master/getMasterTypeValue?masterType=${props.masterTypeValue}`} />
    </Form>
  );
};

const AddMasterValue = ({ onClose, masterTypeValue }: { onClose: any, masterTypeValue: string }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.master);

  const onFormLayoutChange = (values: any) => {
    dispatch(saveOrUpdateMasterValues([{
      ...values,
      masterTypeName: masterTypeValue
    }]));
    onClose();
  };

  return (
    <Form
      name="MasterValueForm"
      layout={'inline'}
      onFinish={onFormLayoutChange}
      className="login-form"
      initialValues={{ status: 'active' }}
      style={{ maxWidth: 'none', padding: '5px' }}
    >
      <Form.Item
        name="masterValue"
        rules={[
          {
            required: true,
            message: "Please input Mater Values !",
          },
        ]}>
        <Input placeholder='Master Value' autoComplete='off' />
      </Form.Item>
      <Form.Item
        name="active"
        initialValue={true}
        rules={[
          {
            required: true,
            message: "Please select status!",
          },
        ]}
      >
        <Select style={{ minWidth: '120px' }} defaultValue={true}
          options={[
            { value: true, label: 'Active' },
            { value: false, label: 'Inactive' },
          ]} />
      </Form.Item>
      <Form.Item name="comment">
        <Input placeholder='Comment' autoComplete='off' />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
          Save
        </Button>
      </Form.Item>
      <Form.Item >
        <Button onClick={onClose}> Cancel </Button>
      </Form.Item>
    </Form>
  );
};

export const Master: React.FC = () => {
  const { masterTypeData, loading, count } = useSelector((state: any) => state.master);

  const dispatch = useDispatch()

  const [state, setState] = useState({
    masterTypeValue: '',
    isFormOpen: false,
    searchText: ''
  });

  const datatableref = useRef();

  const toggleForm = () => {
    setState({ ...state, isFormOpen: !state.isFormOpen });
  };

  const handleChange = (value: string) => {
    setState({ ...state, masterTypeValue: value });
  };

  useEffect(() => {
    if (state.masterTypeValue !== '') {
      (datatableref.current as any)?.refresh();
    }
  }, [state.masterTypeValue, count])

  return (
    <>
      {state.isFormOpen ? <>
        <AddMasterValue masterTypeValue={state.masterTypeValue} onClose={() => toggleForm()} />
      </> : null}
      <>

        <MasterItems searchText={state.searchText} masterTypeValue={state.masterTypeValue}
          datatableref={datatableref}
          extra={
            <Flex gap="large" wrap="wrap">
              <Button
                disabled={state.masterTypeValue === ''}
                onClick={e => toggleForm()}>
                <PlusOutlined />
                Add Master Value
              </Button>

              <Input.Search
                placeholder={`Search ${state.masterTypeValue}`}
                disabled={state.masterTypeValue === ''}
                style={{ width: 200 }}
                onChange={(e) => {
                  setState({ ...state, searchText: e.target.value.trim() })
                }}
              />

              <Select
                loading={loading}
                defaultValue="Select"
                style={{ width: 200 }}
                onChange={handleChange}
                options={masterTypeData.masterType.map((e: any) => ({
                  value: e, label: e
                })) || []}
              />
            </Flex>
          } />
      </>
    </>
  )
}
