import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox, Row } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassApiFunction } from "./slice-login";

const ResetPass = () => {
  const dispatch = useDispatch()
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const {resetloading, data} = useSelector((state:any)=>state.login)
  

  const onFinish = (values: any) => {
    dispatch(resetPassApiFunction({token: searchParams.get('token'), password: form.getFieldsValue().password}))
  };

  return (
    <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
    <Form
      name="reset_password"
      className="login-form"
      form={form}
      initialValues={{
        
      }}
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your new password!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="password"
          type="password"
        />
      </Form.Item>
      <Form.Item
        name="confrimpassword"
        rules={[
          {
            required: true,
            message: "Please input confirm Password!",
          },
          {
            validator: (a, b)=>{
              if(form.getFieldValue('password') !== b)
              return Promise.reject('Password and confrim password are not same')
            }
          }
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="confrim password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit"  loading={resetloading} onClick={onFinish}>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
    </Row>
  );
};

export {ResetPass};