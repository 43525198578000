import { createAsyncThunk } from '@reduxjs/toolkit'
import { popMessage } from '../../App';

const BASE_URL = process.env.REACT_APP_BASE_URL;

type GetClient = {
  (param?: any): string;
}

type DispatchCaller = (dispatch: any, data: any) => void

type HttpData = {
  url: string,
  body: any
}

type HttpClient = {
  (param?: any): HttpData;
}

const PUBLIC_URLS = ['perform-login', 'reset-password', 'request-reset']
const fetchDonwload = async (url: any) => {
  var filename = "";

  // We first call fetch with the URL of the resource we want to download
  fetch(BASE_URL + url, {
    method: "GET",
    headers: { "Authorization": 'Bearer ' + window.localStorage.getItem('token') }
  } as any)
    .then((result) => {
      if (!result.ok) {
        throw Error(result.statusText);
      }

      const header = result.headers.get('Content-Disposition') as string;
      const parts = header.split(';');
      filename = parts[1].split('=')[1].replaceAll("\"", "");

      return result.blob();
    })
    .then((blob) => {
      if (blob != null) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

const fetchCall = async (url: string, method: string, body: any) => {
  try {

    if (method === 'DOWNLOAD') {
      await fetchDonwload(url);
      return;
    }

    var addtoken = PUBLIC_URLS.indexOf(url.split('?')[0]) >= 0 ? ({}) : ({ Authorization: 'Bearer ' + window.localStorage.getItem('token') });
    const datad = await fetch(BASE_URL + url, {
      method: method, // or 'PUT'
      headers: {
        ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
        ...addtoken
      } as any,
      body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
    })
    if (datad.status !== 200 && datad.status !== 201) {
      if (datad.status == 401) {
        return new Promise((resolve, reject) => {
          reject(401)
        })
      }

      datad.json().then((e: any) => {
        popMessage({ content: e.error, type: 'error', duration: 10 })
      })
      //.catch(erro=>console.log(erro))
    }

    return datad.json()
  } catch (error) {

    console.error("Error:", error);
  }
}
// First, create the thunk
const ThunkHttpClient = {
  get: (keyid: string, buildClient: GetClient, dispatchCaller?: DispatchCaller) => createAsyncThunk(keyid, async (data: any, { dispatch }) => {
    const result = await fetchCall(buildClient(data), "GET", undefined);
    if (dispatchCaller) {
      dispatchCaller(dispatch, data);
    }
    return result
  }) as any,
  delete: (keyid: string, buildClient: GetClient, dispatchCaller?: DispatchCaller) => createAsyncThunk(keyid, async (data: any, { dispatch }) => {
    const result = await fetchCall(buildClient(data), "DELETE", undefined);
    if (dispatchCaller) {
      dispatchCaller(dispatch, data);
    }
    return result
  }) as any,

  download: (keyid: string, buildClient: GetClient, dispatchCaller?: DispatchCaller) => createAsyncThunk(keyid, async (data: any, { dispatch }) => {
    const result = await fetchCall(buildClient(data), "DOWNLOAD", undefined);
    if (dispatchCaller) {
      dispatchCaller(dispatch, data);
    }
    return result
  }) as any,
  put: (keyid: string, buildClient: HttpClient, dispatchCaller?: DispatchCaller) => createAsyncThunk(keyid, async (data: any, { dispatch }) => {
    const param = buildClient(data);
    const result = await fetchCall(param.url, "put", param.body);
    if (dispatchCaller) {
      dispatchCaller(dispatch, data);
    }
    return result
  }) as any,
  post: (keyid: string, buildClient: HttpClient, dispatchCaller?: DispatchCaller) => createAsyncThunk(keyid, async (data: any, { dispatch }) => {
    const param = buildClient(data);
    const result = await fetchCall(param.url, "POST", param.body);
    if (dispatchCaller) {
      dispatchCaller(dispatch, data);
    }
    return result
  }) as any,
}



export { ThunkHttpClient }