import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'


// First, create the thunk
export const commonuploadApiFunction = ThunkHttpClient.get('commonupload/upload',  (data)=>(`uploadlist?componentName=${data.componentName}&limit=${data.limit}&page=${data.page}`))



// Define a type for the slice state


// Define the initial state using that type
const initialState: any = {
  loading: false,
  data: [],
  tableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
  }
}

export const counterSlice = createSlice({
  name: 'commonupload',
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder.addCase(commonuploadApiFunction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data.content
      state.tableParams.pagination.current=action.meta.arg.page
      state.tableParams.pagination.pageSize=action.meta.arg.limit
      state.tableParams.pagination.total = action.payload.data.totalElements
    })
    builder.addCase(commonuploadApiFunction.pending, (state, action) => {
      
      
      
      state.loading = true;
    })
    builder.addCase(commonuploadApiFunction.rejected, (state, action) => {
     state.loading = false;
    })
  },

})

export default counterSlice.reducer