import React, { useEffect, useState } from 'react';
import { DatePicker, Modal, Space, Switch, Table, Tag } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    DownloadOutlined,
    ClearOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { downloadInventoryFile, downloadReport } from './slice-ticketInventory';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import { DataTable } from '../common/DataTable';
import { formatDateInTimeZone } from '../utils/CommonFn';





const columns: any = [
    {
        title: 'Download Request By',
        dataIndex: 'creatorName',
    },
    {
        title: 'Product',
        dataIndex: 'productName',
    },
    
    {
        title: 'Customer',
        dataIndex: 'customer',
    },
    {
        title: 'Ref invoice No',
        dataIndex: 'invoice',
    },
    {
        title: 'Status',
        render: (e: any) => e.bucketObject && e.bucketObject.length > 10 ? 'Ready' : 'Creating Pdf',
    },
    // {
    //     title: 'Download Count',
    //     dataIndex: 'count',
    // },
    {
        title: 'ticketcount',
        render: (e: any) => (e.ticketcount || 0),
    },
    {
        title: 'Request Date',
        render: (e: any) => formatDateInTimeZone(e.created),
        
    },
    {
        title: 'Download',        
    },
    // {
    //     title: 'View Detail',
    //     render: () => <Button>Detail</Button>
    // },
    // {
    //     title: 'Download History',
    //     render: (e: any) => e.count === 0 ? <></> : <Button>Download History</Button>
    // },
];




export const TicketInventoryDownload = () => {
    const { downloadRequestData } = useSelector((state: any) => state.ticketInventory);
    const dispatch = useDispatch()
    const [openDownload, setOpenDownload] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState({} as any)

    
    columns[columns.length - 1].render = (el: any) => el.bucketObject && el.bucketObject.length > 10 ? <Button loading={loadingDownload[el.id]} onClick={() => { setLoadingDownload((e: any) => ({ ...e, [el.id]: true })); setTimeout(() => setLoadingDownload((e: any) => ({ ...e, [el.id]: false })), 1500); dispatch(downloadInventoryFile(el)); }}> <DownloadOutlined /> Download</Button> : null;

    return (<> 
            
        <Modal open={openDownload} onCancel={() => setOpenDownload(e => !e)} destroyOnClose
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                <Button type="primary" htmlType="submit">
                            <DownloadOutlined />
                            Downlaod
                        </Button>
                    <Button ><ClearOutlined /> Clear Filter</Button>
                </>
            )}
        >
            <Form
                name="normal_login"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ ticketcount: 10, ref: 'IC23OO034' }}
            >
                <Form.Item
                    name="ticketcount"
                    label="Enter ticket count"
                    rules={[
                        {
                            required: true,
                            message: "Please enter ticket count !",
                        },
                    ]}
                >
                    <Input type='number' disabled placeholder='Enter ticket count' />
                </Form.Item>
                <Form.Item
                    name="ref"
                    label="Enter invoice no"
                    rules={[
                        {
                            required: true,
                            message: "Please enter invoice !",
                        },
                    ]}
                >
                    <Input type='text' disabled placeholder='Please enter invoice' />
                </Form.Item>
                <Form.Item
                    name="reason"
                    label="Add reason"
                    rules={[
                        {
                            required: true,
                            message: "Kindly add reason",
                        },
                    ]}
                >
                    <Input type='text' placeholder='Kindly add reason' />
                </Form.Item>
            </Form>
        </Modal>
        <DataTable
            title="Download List (Sorted By: Download Date)" 
            key="id" columns={columns} 
            componentuniquekey="datatabledownloadlist" 
            lazyPagination={true}
            buildApiUrl={(pageNumber, pagesize)=>`downloadlist?pageSize=${pagesize}&pageNo=${pageNumber}`} /> 
        </>);
}





