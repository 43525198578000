import React, { Children, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { HolderOutlined } from '@ant-design/icons';
import {
  arrayMove,
  SortableContext,
  useSortable,
  
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
  }
  
  const RowContext = React.createContext<RowContextProps>({});
  
  const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{ cursor: 'move' }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };
  


interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props['data-row-key'] });
  
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 999 } : {}),
    };
  
    const contextValue = useMemo<RowContextProps>(
      () => ({ setActivatorNodeRef, listeners }),
      [setActivatorNodeRef, listeners],
    );
  
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };
  

const TableDragableRow = forwardRef(({data, rowKey, onDragFinish, children}: any, ref) => {


  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = data.findIndex((i:any) => i[rowKey] === active.id);
        const overIndex = data.findIndex((i:any) => i[rowKey] === over?.id);
        onDragFinish(arrayMove(data, activeIndex, overIndex));
    }
  };

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={data.map((i:any) => i[rowKey])}
        strategy={verticalListSortingStrategy}
      >
        <Table
         components={{ body: { row: Row } }}
          rowKey={rowKey}
          pagination={{disabled:true, hideOnSinglePage:true, pageSize:200}}
          dataSource={data}
        >
            <Table.Column title="#" render= {() => <DragHandle />} />
            
            {children}
        </Table>
      </SortableContext>
    </DndContext>
  );
});

export {TableDragableRow};