
import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    CloseOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { TableDragableRow } from '../DrageRowTable';
import { useDispatch, useSelector } from 'react-redux';
import { updateDraftQuotation, updateDraftQuotationUiState } from '../slice-itineraryquotation';
import { useForm } from 'antd/es/form/Form';
import { SearchHotelModal } from './HotelModal';
import { useSearchParams } from 'react-router-dom';








const AddFlightModal = ({onOk, open, onCancel}: any) => {
  const {quotation}= useSelector((state:any)=>state.itineraryquotation)
  const [customerfrom] = useForm();
  const dispatch = useDispatch();

  return <Modal 
       title="Flight Detail"
       cancelText="Cancle"
        okText="Add Flight"
      onCancel={onCancel}
       onOk={()=>{
        customerfrom.validateFields().then(()=>{
        customerfrom.submit();
        onOk()
        }).catch(()=>{})
       }}
       open={open}>
       <Form
      name="addflightquotation"
      labelCol={ { span: 8 }}
       wrapperCol={{ span: 16 }}
       form={customerfrom}
       initialValues={{
        child: quotation.quotationInfo.child, 
        adult: quotation.quotationInfo.adult, 
        }}
       onFinish={({ adult, child, from,to, departure, returndate, flightname, costperticket}:any)=>{
        const quotationv = {...quotation}
        quotationv.flights = [...(quotationv.flights || [])];
        quotationv.flights.push({id: dayjs().valueOf(), 
          adult, child, from, to, 
          departure: departure.format('YYYY-MM-DD'), 
          returndate: returndate.format('YYYY-MM-DD'), 
          flightname, 
          costperticket})
          dispatch(updateDraftQuotation(quotationv));     
      }}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}
    >     

<Form.Item
        name={'flightname'}
        label="Flight Name"
        rules={[ {required: true, message: 'Enter Flight Name'}]}
      >
      <Input  />
        </Form.Item> 

        <Form.Item
        name="from"
        required={true}
        rules={[ {required: true, message: 'Enter From'}]}
        label="From"
      >
          <Input  />
      </Form.Item> 
      <Form.Item
        name="to"
        required={true}
        rules={[ {required: true, message: 'Enter To'}]}
        label="To"
      >
          <Input  />
      </Form.Item>

      <Form.Item
        name="adult"
        label="Adult Count"
        required={true}
        rules={[{min: 1, message: 'Enter valid count'}, {required: true, message: 'Enter valid count'}]}
      >
       <Input type='number'/>
        </Form.Item> 

        <Form.Item
        name="child"
        required={true}
        rules={[{required: false, message: 'Select valid date'}]}
        label="Child Count"
      >
      <Input type='number'/>
        </Form.Item> 


        <Form.Item name="departure" label="From Departure" rules={[{required: true, message: 'Select valid date'}]}>
              <DatePicker allowClear={false} disabledDate={(current) =>current && current < dayjs().endOf('day')} />
          </Form.Item>

        <Form.Item name="returndate" label="Return Date" rules={[{required: true, message: 'Enter valid date'}]}>
              <DatePicker allowClear={false} disabledDate={(current) =>current && current < dayjs().endOf('day')} />
        </Form.Item>

        <Form.Item
        name="costperticket"
        required={true}
        rules={[{required: true, message: 'Enter valid amount'}]}
        label="Cost Per Ticket"
      >
      <Input type='number'/>
      </Form.Item>
    </Form>
       </Modal>
}


export const calculateHotelPrice = (rooms:number, extrabed:number, el:any) => {
    var nights = +(el.nights || 0);
    var price = +(el.price || 0);
    var perextrabed = +(el.perextrabed || 0); 
   return +(((rooms * nights * price) + (perextrabed * extrabed * nights)).toFixed(2));
   }


export const QFlights = ({}:any) => {

    const {quotation, saveQuotationdetailloading, uotationuistate}= useSelector((state:any)=>state.itineraryquotation);
    const uistate = uotationuistate;
    const dispatch = useDispatch();
    const ref = useRef<any>();
    const refin = useRef<any>();
    const [form] = useForm();
    var rooms = +(quotation.quotationInfo.rooms || 0);
    var extrabed = +(quotation.quotationInfo.extrabed || 0);


    const setuiState = (state:any) => {
        dispatch(updateDraftQuotationUiState(state))
      }


return <><br/>
      {uistate.addFlightModal? <AddFlightModal open={uistate.addFlightModal} onCancel={()=>setuiState({...uistate, addFlightModal: false })} onOk={()=>setuiState({...uistate, addFlightModal: false })}/> : null }
       <Card title="Flights Info:" extra={
       <Space wrap>Flight Include <Checkbox checked={uistate.flightenable} onChange={e=>setuiState({...uistate, flightenable: !(uistate.flightenable || false)})}/>
       <Divider dashed type='vertical' />
       <Button disabled={!uistate.flightenable} onClick={e=>setuiState({...uistate, addFlightModal: true })}>Add Flight</Button></Space>}>
       
       {
          uistate.flightenable ?  <>
       <TableDragableRow ref={ref} data={quotation.flights || []} 
       onDragFinish={(newdaa:any)=>dispatch(updateDraftQuotation({...quotation, flights: newdaa}))}
       rowKey={'id'} >
                <Table.Column dataIndex="id" title="Id"/>
                <Table.Column dataIndex="flightname" title="Flight Name"/>
                <Table.Column dataIndex="adult" title="Adult"/>
                <Table.Column dataIndex="child" title="Child"/>
                <Table.Column dataIndex="departure" title="Departure Date" />
                <Table.Column dataIndex="returndate" title="Return Date" />
                <Table.Column dataIndex="from" title="From" />
                <Table.Column dataIndex="to" title="To" />
                <Table.Column dataIndex="costperticket" title="Cost Per Ticket" />
                <Table.Column title="Total" render={(el:any)=> (+el.child + +el.adult)*(+el.costperticket)}/>
                <Table.Column title="Action" render={(el:any)=><Button onClick={()=>{
                    const quotationv = {...quotation}
                    quotationv.flights = quotationv.flights.filter((e:any)=>e.id !== el.id)
                    dispatch(updateDraftQuotation(quotationv));

                }}><CloseOutlined /></Button>} />
                </TableDragableRow>
                </> : null
            }
       </Card>
       </>
}