export const formatDateInTimeZone = (date:string) => {

try {
    if(!date.endsWith('Z')) {
        date = date + 'Z';
    }
   const nd: string = convertUTCDateToLocalTimezone(date);
   //console.log(date, nd)
   return nd
} catch (e) {
 return 'NAN'
}
}

// Function to get the client's timezone
function getClientTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Function to convert UTC date to the client's local timezone
function convertUTCDateToLocalTimezone(utcDate: string): string {
    // Create a Date object from the UTC date string
    const date = new Date(utcDate);

    // Get the client's timezone
    const timeZone = getClientTimezone();

    // Use Intl.DateTimeFormat to format the date in the client's local timezone
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-gb', options);
    return formatter.format(date).toUpperCase();
}
