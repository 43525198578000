import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { loginApiFunction } from './slice-login'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "../common/Icon";




const LoginC = () => {
  const dispatch = useDispatch()
  const nav = useNavigate();
  const {loading, data} = useSelector((state:any)=>state.login)
  
 useEffect(()=>{if(data && data.length>10) nav("/home")}, [data])
  const onFinish = (values: any) => { 
    dispatch(loginApiFunction({username: values.username, password: values.password}))
  };

  return (
    <Row   className="loginBackground loginPage">
      <Col span={12} className="leftPlane" />
      <Col span={12} className="loginContainer">   
        <div className="vectorPlane">
          <img alt="" src="assets/images/plane.svg" />
        </div>
        <div className="vectorMonument">
          <img alt="" src="assets/images/monuments.svg" />
        </div>
        <div className="logoBox">
          <img className="logoIcon" alt="" src="assets/images/logo.png" />
        </div>
        <span className="loginTitle col-12 d-flex justify-content-center">WelCome !</span>
        <br />
        <div className="login-form">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={
                <Icon icon="et-email" style={{color: '#fff', fontSize: '17px', padding: '1px 10px'}} />
              }
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={ <Icon icon="et-key" style={{color: '#fff', fontSize: '18px', padding: '1px 10px'}} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <div>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                Log in
              </Button><br/>
              <a className="login-form-forgot" href="#/forgot">
                Forgot password
              </a>
              </div>
              
            </Form.Item>
            
          </Form>
          </div>
    </Col>
    </Row>
  );
};

export { LoginC };
