import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber, Radio } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    
    CloseOutlined,
    DeleteOutlined,
    ClearOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import { DataTable } from '../common/DataTable';
import { itineraryQuotationProductSearch, addProductToQuotation, itineraryFetchCustomerByEmail, updateDraftQuotation, itineraryFetchHotelByName, addHotelToQuotation, itinerarySaveHotel, itinerarySaveQuotationDetail, itinerarySaveCustomerDetail, updateDraftQuotationUiState, resetquota, itineraryDeleteHotel, itineraryDeleteProduct, saveProductOfIntenotryproduct } from './slice-itineraryquotation';
import { key } from 'localforage';
import { TableDragableRow } from './DrageRowTable';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { stat } from 'fs';
import { QHotelList, calculateHotelPrice } from './createQuotation/QHotelList';
import { QTourList } from './createQuotation/QProductList';
import { QVisa } from './createQuotation/Visa';
import { QFlights } from './createQuotation/QFlight';










const CustomerFormPopup = ({onOk, open, onCancel}: any) => {
  const [params] = useSearchParams();
  const {quotation}= useSelector((state:any)=>state.itineraryquotation)
  const customerid = params.get('id') || ''
  const [customerfrom] = useForm();
  const dispatch = useDispatch();
  const nav = useNavigate();
  useEffect(() => {
    if(customerid !== '')
    dispatch(itineraryFetchCustomerByEmail({email: customerid})).then((data:any)=> {
    const quotationv = {...quotation, customerdetail: data.payload.data[0]}
    
    const {email,mobile,name,whatsapp,address, id} = quotationv.customerdetail;
    customerfrom.setFieldsValue({email,mobile,name,whatsapp,address, id});
    dispatch(updateDraftQuotation(quotationv));
  })
  }, [])

  return <Modal 
       title="Customer Detail"
       cancelText="Cancle & Return"
      onCancel={onCancel}
       onOk={()=>{
        customerfrom.validateFields().then(()=>{
        customerfrom.submit();
        onOk()
        }).catch(()=>{})
       }}
       open={open}>
       <Form
      name="customermodalformsdfsd"
      labelCol={ { span: 8 }}
       wrapperCol={{ span: 16 }}
       form={customerfrom}
       initialValues={{child: 0}}
       onFinish={({email,mobile,name,whatsapp, id, address, adult, child, fromto}:any)=>{
        const quotationv = {...quotation}
        quotationv.customerdetail= {email,mobile,name,whatsapp, id, address};
        quotationv.quotationInfo = { ... quotation.quotationInfo, 
          visaadultname: 'Dubai Tour Visa',
          visachildname: 'Dubai Tour Visa',
          visachildprice: 0, visaadultprice: 0,
          visaadultcount: adult, visachildcount: child , adult, child, 
          from: dayjs(fromto[0]).format('DD-MM-YYYY'), to: dayjs(fromto[1]).format('DD-MM-YYYY')}
        dispatch(updateDraftQuotation(quotationv));       
      }}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}
    >     

<Form.Item
        name="id"
      >
      <Input  style={{display: 'none'}}/>
        </Form.Item> 
    <Form.Item
        name="email"
        label="Customer Email"
        required={true}
        rules={[ {required: true, message: 'Enter email address'}]}
      >
      <Input type='email' onBlur={(value)=>dispatch(itineraryFetchCustomerByEmail({email:value.target.value})).then((el:any)=>{
        if(el.payload.data && el.payload.data.length>0) {
          const d = el.payload.data[0];
          nav('/home/itinerary/quotation?id='+d.email )
          const quotationv = {...quotation, customerdetail: d}
          const {email,mobile,name,whatsapp, address, id} = quotationv.customerdetail;
          customerfrom.setFieldsValue({email,mobile,name,whatsapp,address, id});
          dispatch(updateDraftQuotation(quotationv));
        }
         
      })} disabled={customerid !== ''}/>
        </Form.Item> 
        <Form.Item
        name="mobile"
        required={true}
        rules={[ {required: true, message: 'Enter mobile no'}]}
        label="Customer Mobile"
      >
      <Input  disabled={customerid !== ''}/>
        </Form.Item> 
        <Form.Item
        name="name"
        required={true}
        rules={[ {required: true, message: 'Customer number'}]}
        label="Customer Name"
      >
      <Input disabled={customerid !== ''}/>
        </Form.Item> 
        <Form.Item
        name="whatsapp"
        required={true}
        rules={[ {required: true, message: 'Enter whatsapp no'}]}
        label="Customer Whats no"
      >
      <Input disabled={customerid !== ''}/>
        </Form.Item> 
        
        <Form.Item
        name="address"
        required={true}
        rules={[ {required: true, message: 'Enter address no'}]}
        label="Customer Address"
      >
      <Input disabled={customerid !== ''}/>
        </Form.Item> 

        <Form.Item
        name="adult"
        label="Adult Count"
        required={true}
        rules={[{min: 1, message: 'Enter valid count'}, {required: true, message: 'Enter valid count'}]}
      >
       <Input type='number'/>
        </Form.Item> 
        <Form.Item
        name="child"
        required={true}
        
        label="Child Count"
      >
      <Input type='number'/>
        </Form.Item> 
     <Form.Item name="fromto" label="From Date" rules={[{required: true, message: 'Select valid range'}]}>
          <DatePicker.RangePicker allowClear={false} disabledDate={(current) =>current && current < dayjs().endOf('day')} />
      </Form.Item>
    </Form>
       </Modal>
}



export const ItineraryQuotation = () => {

  const nav = useNavigate();
  const [params] = useSearchParams();
  const customerid = params.get('id') || ''
  const dispatch = useDispatch();
  useEffect(()=>{
    //console.log('loadded this comonpoetn')
    dispatch(itineraryQuotationProductSearch(''))
    dispatch(itineraryFetchHotelByName(''))
    return ()=> {
      dispatch(resetquota(''))
    }    

  }, [])
  const [modal, contextHolder] = Modal.useModal();



  
   const isBlank = (val:any) => val === undefined || val === null || (val+"")==='';




  const {quotation, saveQuotationdetailloading, uotationuistate}= useSelector((state:any)=>state.itineraryquotation);
  
  const uistate = uotationuistate;

  
//console.log(quotation, uistate)
  const setuiState = (state:any) => {
    dispatch(updateDraftQuotationUiState(state))
  }

  const getProductTotal = (e:any) => +(e.transferType === 'Private' ? +(e.totalprice || 0) :  totalseat*(e.price || 0));
  var rooms = +(quotation.quotationInfo.rooms || 0);
  var extrabed = +(quotation.quotationInfo.extrabed || 0);
  let totalseat = +quotation.quotationInfo.adult + +quotation.quotationInfo.child
  let totalhr = uistate.hotelenable ?  quotation.hotels.reduce((lastprice:number, el:any )=>calculateHotelPrice(rooms, extrabed, el) +lastprice, 0) : 0;
  let totalProduct =  +(uistate.tourenable ?   quotation.products.reduce((lastprice:number, e:any )=> getProductTotal(e) +lastprice
  , 0) : 0);
  let flightstotal = uistate.flightenable? (quotation.flights || []).reduce((lastprice:number, el:any )=> lastprice + (+el.child + +el.adult)*(+el.costperticket), 0) : 0
 
  
  
  if(uistate.visaenable) {
  let visaadultpricing = (+quotation.quotationInfo.visaadultcount || 0) * (+quotation.quotationInfo.visaadultprice  || 0);
  let visachildpricing = (+quotation.quotationInfo.visachildcount || 0) * (+quotation.quotationInfo.visachildprice || 0);
  totalProduct += +visaadultpricing + +visachildpricing;
  }
  let calculatedTotal = +totalProduct + +totalhr + +flightstotal;

  let isFlatPrice = uistate.flattotal || uistate.flattotal>0;

  calculatedTotal =  +calculatedTotal ;
  calculatedTotal = +calculatedTotal.toFixed(2);

  let total = isFlatPrice ? uistate.flattotal : calculatedTotal;
  
  let totalAfterDiscuount = 0;
  if(uistate.discounttype === 'flatdiscount') {
    totalAfterDiscuount = total -  uistate.discount;
  } else {
    totalAfterDiscuount = total - (total * uistate.discount / 100) as any; 
  }
  totalAfterDiscuount = +totalAfterDiscuount.toFixed(2);
  

 

    // modal.confirm({
    //   title: <>Confirm</>,
    //   content: 'All changes will be disgraded, Want to disgard and return to quotation list',
    //   okText: 'Confirm',
    //   onOk: (el)=> {
    //         nav('/home/itinerary/quotationlist')
    //      },
    //   onCancel: (el)=> { el();},
    //   footer: (_, { OkBtn, CancelBtn }) => (
    //     <>
    //       <CancelBtn  />
    //       <OkBtn />
    //     </>
    //   ),
    // })
   



  const saveAfterValidation = (tobesend?:boolean) => {
    var c = {
      adultCount: quotation.quotationInfo.adult ,
      childCount: quotation.quotationInfo.child,
      fromDate: dayjs(quotation.quotationInfo.from, "DD-MM-YYYY").toISOString() ,
      toDate: dayjs(quotation.quotationInfo.to,"DD-MM-YYYY").toISOString() ,
      isVisa: uistate.visaenable,
      isTour: uistate.tourenable,
      isHotel: uistate.hotelenable,
      isFightEnable: uistate.flightenable,
      quotationDetails: JSON.stringify({
        flights: (quotation.flights || []).map((el:any)=>({...el, totalcost: (+el.child + +el.adult)*(+el.costperticket)})),
        product: quotation.products.map((e:any)=>({
        ...e, total: getProductTotal(e), adult: quotation.quotationInfo.adult, child: quotation.quotationInfo.child, 
      })), hotels: quotation.hotels.map((el:any)=>({...el, totalcost: +el.price})), 
      visainfo: {
        visaadultname : quotation.quotationInfo.visaadultname,
        visachildname : quotation.quotationInfo.visachildname ,
        visaadultcount : quotation.quotationInfo.visaadultcount ,
        visachildcount : quotation.quotationInfo.visachildcount ,
        visaadultprice : quotation.quotationInfo.visaadultprice ,
        visachildprice : quotation.quotationInfo.visachildprice ,
      }, 
      quotationInfo: {
        adultCount: quotation.quotationInfo.adult,
        childCount: quotation.quotationInfo.child,
        fromDate: dayjs(quotation.quotationInfo.from, "DD-MM-YYYY").toISOString() ,
        toDate: dayjs(quotation.quotationInfo.to,"DD-MM-YYYY").toISOString() ,
        isVisa: uistate.visaenable,
        isTour: uistate.tourenable,
        isHotel: uistate.hotelenable,
        isFightEnable: uistate.flightenable,
        vat: uistate.vat,
        discounttype: uistate.discounttype,
        discount: uistate.discount,
        calculatedTotal: calculatedTotal,
        isFlatPrice: isFlatPrice,
        flattotal: uistate.flattotal,
        totaltourcost: isFlatPrice? uistate.flattotal : calculatedTotal,
        totaltafrourcost: totalAfterDiscuount
      }})
    }
// console.log(c, JSON.parse(c.quotationDetails))
// return
    if(quotation.customerdetail.id) {
      dispatch(itinerarySaveQuotationDetail({customerid: quotation.customerdetail.id, sendToEmail: tobesend, body: c})).then((e:any)=>{
        dispatch(resetquota(''))
        nav('/home/itinerary/quotationlist')
    })
    } else {
      
       dispatch(itinerarySaveCustomerDetail(quotation.customerdetail)).then((e:any)=>[
        dispatch(itinerarySaveQuotationDetail({customerid: e.payload.data.id,sendToEmail: tobesend, body: c})).then((e:any)=>{
          dispatch(resetquota(''))
          nav('/home/itinerary/quotationlist')
    })
      ])
    }
  }

   const onSaveQutation = (tobesend?:boolean) => {

    


    if(uistate.flightenable ) {
      if(quotation.flights.length === 0) {
        modal.error({title: 'Kindly add flights to quotation if flight is enable'})
        return;
      }
  
      
      if( quotation.flights.filter((e:any)=> (+e.costperticket)<=0).length > 0) {
        modal.error({title: 'Kindly add ticketcost into flight'})
        return;
      }

      if(quotation.flights.filter((e:any)=> ((+e.adult) + (+e.child))<=0).length > 0) {
        modal.error({title: 'Kindly add ticket count into flight info'})
        return;
      }
    }
    

    if(uistate.visaenable ) {
      if(+quotation.quotationInfo.visaadultcount>0 && (+quotation.quotationInfo.visaadultprice  || 0) == 0) {
        modal.error({title: 'Kindly correct the visa price if visacount is more that zero'})
        return;
      }
      if(+quotation.quotationInfo.visachildcount>0 && (+quotation.quotationInfo.visachildprice  || 0) == 0) {
        modal.error({title: 'Kindly correct the visa price if visacount is more that zero'})
        return;
      }
      if(+quotation.quotationInfo.visaadultcount == 0 && +quotation.quotationInfo.visachildcount) {
        modal.error({title: 'Kindly correct the visa count if visa is enabled'})
        return;
      }
     
    }
    

    
    if(quotation.tourenable && quotation.products.length === 0) {
      modal.error({title: 'Kindly add products to quotation if product is enable'})
      return;
    }

    
    
    if(uistate.tourenable) {
    if( quotation.products.filter((e:any)=>isBlank(e.transferType)).length > 0) {
      modal.error({title: 'Kindly select transfer type for each tour'})
      return;
    }
    if(quotation.products.filter((e:any)=>isBlank(e.date) || isBlank(e.time) || (calculatedTotal !== 0 &&  +getProductTotal(e) === 0) ).length > 0) {
      modal.error({title: 'Kindly add products date,time,price for quotation tour'})
      return;
    }

    
    var f = quotation.products.find((e:any, i:number)=>{
      let j = dayjs(e.date, 'DD-MM-YYYY').isBefore(dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')) || 
      dayjs(e.date, 'DD-MM-YYYY').isAfter(dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY')) ;

      if(j) {
        return j;
      }
      if(i===0) {
        return false;
      }

      return dayjs(e.date, 'DD-MM-YYYY').isBefore(dayjs(quotation.products[i-1].date, 'DD-MM-YYYY')
      ||  dayjs(e.date, 'DD-MM-YYYY').isSame(dayjs(quotation.products[i-1].date, 'DD-MM-YYYY')));

    })

    if(f) {
      modal.error({title: 'Kindly check tour date and time. Tour Products should be in order '})
      return;
    }

  }

  


    if(uistate.hotelenable && quotation.hotels.length === 0) {
      modal.error({title: 'Kindly add hotels'})
      return;
    }

    if(uistate.hotelenable) {
      if(calculatedTotal !== 0 && quotation.hotels.filter((e:any)=>+e.price === 0).length > 0) {
        modal.error({title: 'Kindly add price for hotels item'})
        return;
      }

      var f = quotation.hotels.find((e:any, i:number)=>{
        let j = e.checkindate === undefined || e.checkindate === null || e.checkoutdate === undefined 
        || e.checkoutdate === null 
        || dayjs(e.checkindate, 'DD-MM-YYYY').isBefore(dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')) 
        || dayjs(e.checkindate, 'DD-MM-YYYY').isBefore(dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')) 
        ||  dayjs(e.checkindate, 'DD-MM-YYYY').isAfter(dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY'))
        || dayjs(e.checkoutdate, 'DD-MM-YYYY').isAfter(dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY')) 
        || dayjs(e.checkindate, 'DD-MM-YYYY').isSame(dayjs(e.checkoutdate, 'DD-MM-YYYY')) ;
       
        if(j) {
          return j;
        }
        if(i===0) {
          return false;
        }
  
        return dayjs(e.checkindate, 'DD-MM-YYYY').isBefore(dayjs(quotation.hotels[i-1].checkoutdate, 'DD-MM-YYYY'));
        
      })
  
      if(f) {
        modal.error({title: 'Kindly check checkin & Checkout date And Verify the Checkin, Checkout date order for the Hotels'})
        return;
      }
    }

  if(!uistate.tourenable) {

    if(!uistate.hotelenable && !uistate.visaenable) {
      modal.error({title: 'Kindly add hotels or tour or visa'})
      return;
    }

    modal.confirm({title: 'Are you want to procced without tour?', onOk: ()=>{
      saveAfterValidation(tobesend);
    }});
    return;
  }
  
  saveAfterValidation(tobesend);

  }
  
 return (<>
       {contextHolder}
       {uistate.customer.id === 0? <CustomerFormPopup 
        onCancel={()=>{
          dispatch(resetquota(''));
          nav('/home/itinerary/quotationlist')
        }}
       onOk={()=>[
        setuiState({...uistate, customer: {id: 1} })
       ]}
       open={uistate.customer.id === 0}
       
       /> : null}
       <Card loading={saveQuotationdetailloading}  extra={<>
        <Button type='primary' onClick={()=>
          modal.confirm({
            title: <>Confirm to return</>,
            content: 'All changes will be disgraded, Want to disgard and return to quotation list',
            okText: 'Confirm',
            onOk: (el)=> {
              dispatch(resetquota(''))
                  nav('/home/itinerary/quotationlist')
               },
            onCancel: (el)=> { el();},
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn  />
                <OkBtn />
              </>
            ),
          })
          
          
          }> Cancel & Return </Button> {" "}
       <Button type='primary' onClick={()=>onSaveQutation(false)}>Save</Button> {" "}
       <Button type='primary' onClick={()=>onSaveQutation(true)}>Save and Send Email</Button>
       </>}>
       <Descriptions title="Custmer Info">
          <Descriptions.Item label="Customer Name">{quotation.customerdetail.name}</Descriptions.Item>
          <Descriptions.Item label="Telephone">{quotation.customerdetail.mobile}</Descriptions.Item>
          <Descriptions.Item label="Whatsapp">{quotation.customerdetail.whatsapp}</Descriptions.Item>
          <Descriptions.Item label="Email">{quotation.customerdetail.email}</Descriptions.Item>
          <Descriptions.Item label="Address">
          {quotation.customerdetail.address}
          </Descriptions.Item>
          <Descriptions.Item label="From">{quotation.quotationInfo.from}</Descriptions.Item>
          <Descriptions.Item label="To">{quotation.quotationInfo.to}</Descriptions.Item>
          <Descriptions.Item label="Adult">{quotation.quotationInfo.adult}</Descriptions.Item>
          <Descriptions.Item label="Child">{quotation.quotationInfo.child}</Descriptions.Item>
        </Descriptions>
       </Card>
       
       <br/>
       <QFlights /> 
       <br/>
       <QVisa />
       <br/>
       <QTourList />
       <br/>
       <QHotelList />
       
         <Divider />
         <Card title="Amount Summary:">
         <div className='amountSummaryBox'>
              <Row>
                <Col span={10} className='amountField'>
                <span>Flat Total Price : </span>
                <Input  type='number' value={uistate.flattotal} onChange={e=>setuiState({...uistate, flattotal: +e.target.value })}/> 
                
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
                <span><Radio.Group value={uistate.discounttype || 'percentagediscount'} onChange={e=>{
                  setuiState({...uistate, discounttype: e.target.value, discount: 0 })}
                  }>
                  <Radio value={'flatdiscount'}>Flat Discount</Radio>
                  <Radio value={'percentagediscount'}>% Discount</Radio>
                </Radio.Group></span>
                <Input  type='number' max={ uistate.discounttype === 'flatdiscount' ? 999999999: 99} value={uistate.discount} onChange={e=>setuiState({...uistate, discount: +e.target.value })}/> 
                </Col>
                
              </Row>
              <Row>
                <Col span={10} className='amountField'>
                <span>Grand Total: </span> 
                { calculatedTotal }
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
               </Col>
                
              </Row>
              <Row className='grandTotalBox'>
                <Col span={8} className='grandTotal'>
                  <span>
                    Final Total :
                  </span>
                  <span className='fontLarge'>
                    {totalAfterDiscuount}
                  </span>
                 </Col>
              </Row>
             </div>
        </Card>

         <Divider />
         <Divider />
        </>);
}
