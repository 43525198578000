import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { productWiseCountReport } from './slice-ticketInventory';
import { truncate } from 'fs';
import ReactECharts from 'echarts-for-react';

// Register the required components

const map = {
    uploadedpdfadult: 'Uploaded Pdf Adult',
    uploadedpdfchild: 'Uploaded Pdf Child',
    uploadedmediachild: 'Uploaded Media Child',
    uploadedmediaadult: 'Uploaded Media Adult',
    availpdfadult: 'Available Pdf Adult',
    availpdfchild: 'Available Pdf Child',
    availmediaadult: 'Available Media Child',
    availmediachild: 'Available Media Adult',
} as any

const baroption = {
    color: [
      
      ],
    tooltip: {
        extraCssText: 'z-index: 23232323',
        position: ['30%', '30%']
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      lineHeight: 30,
      itemWidth: 20,
      textStyle: {
        width: 70,
        fontSize: 10,
        overflow: 'truncate'
      },
      nameTruncate: {
        maxWidth: 10,
        ellipsis: "..."
      },
    },
    series: [
      
    ]
  } as any;

const getOptionVp = (data:any, name:string, avail=false) => {
    if(data == null || data === undefined) {
        return {};
    }
const oo = {...baroption}
oo.color = !avail? [
    '#8a1e1d',
    '#be4946',
    '#dc6f6e',
    '#d48265',
  ]:[
    '#518b77',
    '#3EB489',
    '#67CBA6',
    '#96DBC2'
  ];
oo.series = [{
    name: name,
    type: 'pie',
    radius: ['40%', '70%'],
    avoidLabelOverlap: false,
    label: {
        show: false,
        position: 'center'
      },
    data: avail? [
        { value: data.availpdfadult, name: map.availpdfadult },
        { value: data.availpdfchild, name: map.availpdfchild },
        { value: data.availmediaadult, name: map.availmediaadult },
        { value: data.availmediachild, name: map.availmediachild },
      ]:  [
        { value: data.uploadedpdfadult, name: map.uploadedpdfadult },
        { value: data.uploadedpdfchild, name: map.uploadedpdfchild },
        { value: data.uploadedmediachild, name: map.uploadedmediachild },
        { value: data.uploadedmediaadult, name: map.uploadedmediaadult },
      ],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  }]

  return oo;
}

const IteneraryDashBoard = () => {

    const [uistate, setUiState] = useState<any>({ vploading:false, vpoption: {}, nvploading:false, nvpoption: {}});
    const dispatch = useDispatch();


    const fetchBarChart = (type:string) => {
        var option = {
        
            color: [
                '#8a1e1d',
    '#be4946',
    '#dc6f6e',
    '#d48265',
                '#518b77',
    '#3EB489',
    '#67CBA6',
    '#96DBC2'
              ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                legend: {
                    orient: 'horizontal',
                    textStyle: {
                        width: 70, //set to grid right - item width (default 25) - 5 set tooltip right to grid right
                        overflow: "break",
                        borderRadius: 12,
                        }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    nameTruncate: {
                        maxWidth: 60,
                        ellipsis: "..."
                      },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        rotate: 45,
                        overflow: 'truncate',
          width: 80
                      },
    
                      axisTick: {
                        show: true,
                        interval: 0
                      },
                    type: 'category',
                    data: []
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                ]
              } as any;

        setUiState((prev:any)=>({ ...prev, [type+'loading']:true, [type+'option']: {}}))
        dispatch(productWiseCountReport({ ticketnature: type})).then((e:any)=>{
            const d = e.payload.data;
            const seriese = {
                category: [],
                uploadedpdfadult: [],
                uploadedpdfchild: [],
                uploadedmediaadult: [],
                uploadedmediachild: [],
                availpdfadult: [],
                availpdfchild: [],
                availmediaadult: [],
                availmediachild: [],
            } as any
            for(let i of d) {
                seriese.category.push(i.product_name)
                seriese.uploadedpdfadult.push(i.total_adult)
                seriese.uploadedpdfchild.push(i.total_child)
                seriese.uploadedmediaadult.push(i.total_code_adult)
                seriese.uploadedmediachild.push(i.total_code_child)
                seriese.availpdfadult.push(i.total_adult- i.total_downloaded_adult)
                seriese.availpdfchild.push(i.total_child - i.total_downloaded_child)
                seriese.availmediaadult.push(i.total_code_adult - i.total_downloaded_code_adult)
                seriese.availmediachild.push(i.total_code_child - i.total_downloaded_code_child)
            }
            option.xAxis[0].data= seriese.category;
            
            const rp =  {
                uploadedpdfadult: seriese.uploadedpdfadult.reduce((el:number, total:number)=>el+total, 0),
                uploadedpdfchild: seriese.uploadedpdfchild.reduce((el:number, total:number)=>el+total, 0),
                uploadedmediaadult: seriese.uploadedmediaadult.reduce((el:number, total:number)=>el+total, 0),
                uploadedmediachild: seriese.uploadedmediachild.reduce((el:number, total:number)=>el+total, 0),
                availpdfadult: seriese.availpdfadult.reduce((el:number, total:number)=>el+total, 0),
                availpdfchild: seriese.availpdfchild.reduce((el:number, total:number)=>el+total, 0),
                availmediaadult: seriese.availmediaadult.reduce((el:number, total:number)=>el+total, 0),
                availmediachild: seriese.availmediachild.reduce((el:number, total:number)=>el+total, 0),
            }
            for(let j of ['uploadedpdfadult', 'uploadedpdfchild', 'uploadedmediaadult', 'uploadedmediachild']) {
            option.series.push({
                name: map[j],
                type: 'bar',
                stack: 'uploaded',
                barMaxWidth: 30,
                label: {
                    show: true,
                    formatter: (params:any) => params.value>0?params.value: '' 
                  },
                emphasis: {
                  focus: 'series'
                },
                data: seriese[j]
              })
              
            }
            for(let j of ['availpdfadult', 'availpdfchild', 'availmediaadult', 'availmediachild']) {
                option.series.push({
                    name: map[j],
                    type: 'bar',
                    stack: 'available',
                    barMaxWidth: 20,
                    label: {
                        show: true,
                        formatter: (params:any) => params.value>0?params.value: '' 
                      },
                    emphasis: {
                      focus: 'series'
                    },
                    data: seriese[j]
                  })
                  
                }
            setUiState((prev:any)=>({ ...prev, [type+'loading']:false, [type+'option']: option , [type]: rp} ))
          });
    }
    
    useEffect(()=>{
       
    
        fetchBarChart('vp')
        fetchBarChart('nvp');
      

    }, [])

    return <>
    <Row gutter={16}>
        <Col span={6} className='topChartCard'>
                    <Card loading={uistate.vploading} title="Total uploaded: Open date ticket">
                            <ReactECharts
                                option={getOptionVp(uistate.vp, 'Uploaded Open Date')}
                                notMerge={true}
                                lazyUpdate={true}
                            />
                    </Card>
        </Col>
        <Col span={6} className='topChartCard'>
                    <Card loading={uistate.nvploading} title="Total uploaded: Dated ticket">
                            <ReactECharts
                                option={getOptionVp(uistate.nvp, 'Uploaded Dated')}
                                notMerge={true}
                                lazyUpdate={true}
                            />
                    </Card>
        </Col>
        <Col span={6} className='topChartCard'>
                <Card loading={uistate.vploading} title="Total available: Open date ticket">
                            <ReactECharts
                                option={getOptionVp(uistate.vp, 'Available Open Date', true)}
                                notMerge={true}
                                lazyUpdate={true}
                            />
                    </Card>
        </Col>
        <Col span={6} className='topChartCard'>
                <Card loading={uistate.nvploading} title="Total available: Dated ticket">
                            <ReactECharts
                                option={getOptionVp(uistate.nvp, 'Available Dated', true)}
                                notMerge={true}
                                lazyUpdate={true}
                            />
                    </Card>
        </Col>
    </Row>
    <br/>
    <Row gutter={16} >
        <Col span={24} >
    <Card loading={uistate.vploading} title="Product wise total uploaded vs Available: Open date ticket">
        <ReactECharts
            option={{...uistate.vpoption}}
            notMerge={true}
            lazyUpdate={true}
          />
   </Card>
   </Col>
   </Row>
   <br/>
   <Row gutter={16} >
   <Col span={24} >
   <Card loading={uistate.nvploading} title="Product wise total uploaded vs Available: Date Range ticket">
        <ReactECharts
            option={{...uistate.nvpoption}}
            lazyUpdate={true}
          />
   </Card>
   </Col>
   </Row>
    </>
}

export {IteneraryDashBoard}



