import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    
    CloseOutlined,
    DeleteOutlined,
    ClearOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { itineraryQuotationProductSearch, addProductToQuotation, itineraryFetchCustomerByEmail, updateDraftQuotation, itineraryFetchHotelByName, addHotelToQuotation, itinerarySaveHotel, itinerarySaveQuotationDetail, itinerarySaveCustomerDetail, updateDraftQuotationUiState, resetquota, itineraryDeleteHotel, itineraryDeleteProduct, saveProductOfIntenotryproduct }
 from '../slice-itineraryquotation';
import { key } from 'localforage';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { stat } from 'fs';

const option = Array(30).fill(1).map((e,i)=>({label: i, value: i}))

const RateComponent = ({rate, setRate}: any) => {

    const [d, setD] = useState(rate);
    useEffect(()=>{
      setD(rate);
      // setTimeout(()=>setD(0), 10)
    }, [rate])
    //console.log(rate)
    return  <Rate value={d} onChange={(value)=>{ setD(value); setRate(value) } }/>
  }
  


 const AddHotelPop = ({onOk, rating, onCancel,searchtext, updateRating}: any) => <Modal
    title={<>Select the rating for <span style={{fontSize: '20px'}}>{searchtext}</span></>}
    open={true} width={300} 
    style={{ zIndex: 999991}}
        okText="Save Hotel"
        onOk={()=>{
          onOk()
        }}
        onCancel={onCancel}>
<RateComponent rate={rating} setRate={(value:any)=>{updateRating(value) } }/>
        </Modal>

export const SearchHotelModal = ({open, onCancel} : any) => {

  const [state, setState] = useState({selectedRows: [] as any, count: 0, searchtext: '', rating: 4, addHotel: false});
  const {hoteldata, savehotelloading, quotation, quotaionproductdeleteeloading}= useSelector((state:any)=>state.itineraryquotation)
  const dispatch = useDispatch();
  const [formroom] = Form.useForm();
  const onHotelFormLayoutChange = () => {
    formroom.validateFields().then(e=>{
    const quotationv = {...quotation}
    quotationv.quotationInfo = {...quotationv.quotationInfo, rooms: (formroom.getFieldValue('rooms') || 0), extrabed: (formroom.getFieldValue('extrabed') || 0)}
    quotationv.hotels = [...quotationv.hotels, ...hoteldata.filter((e:any)=>state.selectedRows.indexOf(e.id)>=0).map((e:any)=>({...e, perextrabed: 0, price: 0, cost: 0, nights: 0, rooms: formroom.getFieldValue('rooms'), extrabed: formroom.getFieldValue('extrabed')}))]
    dispatch(updateDraftQuotation(quotationv));
    onCancel()
  }).catch(e=>console.error(e))

  };


  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: state.selectedRows,
    onSelect: (selectedRowKeys: any) => {
      if(state.selectedRows.indexOf(selectedRowKeys.id)<0) {
        setState((statea)=> ({ ...statea, selectedRows: [...statea.selectedRows, selectedRowKeys.id]}))
       } else {
        setState((statea)=> {
          const n = [...state.selectedRows];
              const index = state.selectedRows.indexOf(selectedRowKeys.id);
            if (index > -1) {
               n.splice(index, 1); 
             }
          return { ...statea, selectedRows: n}
        
       })
       }
    },
    onSelectNone: () => {
      setState((statea)=> ({ ...statea, selectedRows: []}))
    },
    onSelectAll: (selectedRowKeys: any) => {
      if(selectedRowKeys) {
      setState((statea)=> ({ ...statea, selectedRows: hoteldata.map((a:any)=>a.id)}))
      } else {
        setState((statea)=> ({ ...statea, selectedRows: []}))
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.id === 0, // Column configuration not to be checked
      name: record.id,
      key: record.id
    }),
  };
  const [modal, contextHolder] = Modal.useModal();
 
  const addedIds = quotation.hotels.map((e:any)=>e.id)

const data = hoteldata.filter((v:any)=> addedIds.indexOf(v.id) < 0 && v.name.toUpperCase().includes(state.searchtext.toUpperCase()))


  return (
    <>
    {contextHolder}
    <Modal open={open} width={900} 
    style={{ zIndex: 99999}}
        okText="Add Hotel"
        okButtonProps={{disabled: state.selectedRows.length === 0}}
        onOk={()=>{
          onHotelFormLayoutChange()
        }}
        onCancel={onCancel}>
      {
        state.addHotel ?
        <AddHotelPop open={true} searchtext={state.searchtext} rating={state.rating} onOk={()=>{
          setState(prev=> ({...prev, addHotel: false}))
          dispatch(itinerarySaveHotel({name: state.searchtext, rating: state.rating })).then(()=>{ dispatch(itineraryFetchHotelByName(''))})
        }} onCancel={()=>{
          setState(prev=> ({...prev, addHotel: false})) 
        }}  updateRating={(value:any)=>{setState(prev=> ({...prev, rating: value}))}}/> : null
       }
    <Form
      name="itinaroyhotelqutationsearch"
      layout={'inline'}
      onValuesChange={e=>setState({...state, count: state.count+ 1})}
      onFinish={onHotelFormLayoutChange}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}
    >     
    <Form.Item
        name="search"
        label="Serach Hotel Name"
      
      >
      <Input onChange={(value:any)=>setState({...state, searchtext: value.target.value})}/>
        </Form.Item> 

        {
          state.searchtext.length > 0 && data.length === 0 ? <Button type='primary'
          onClick={(e)=> { setState(prev=> ({...prev, addHotel: true})) 
          }} 
          
          > Save New Hotel</Button> : null
        }
        </Form>
    <Table
        dataSource={data}
         
        rowSelection={rowSelection as any}  rowKey={'id'}>
              <Table.Column dataIndex="id" title="id"/>
              <Table.Column dataIndex="name" title="Hotel Name"/>
              <Table.Column title="Stars" dataIndex="rating" />
              <Table.Column title="Delete" render={(e:any)=> <Button loading={e.id === quotaionproductdeleteeloading} onClick={() => dispatch(itineraryDeleteProduct(e.id)).then(()=>{
                dispatch(itineraryFetchHotelByName(''))
              })}>
                <DeleteOutlined />
                 </Button>}/>
             </Table> 

             



             <Form
      name="itinaroyhotelqutationsearchaddsdfsdf"
      layout={'inline'}
      onValuesChange={e=>setState({...state, count: state.count+ 1})}
      form={formroom}
      initialValues={{  
        rooms: quotation.quotationInfo.rooms || 1, 
        extrabed: quotation.quotationInfo.extrabed || 0 }}
      requiredMark={true}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}
    >
             {/* <Form.Item
              name="nights"
              label="Nights"
              required={true}
              rules={[{required: true, message: "required"}]}
            >
              <Select style={{minWidth: '100px'}}>
                {option.map((e,i)=><Select.Option key={i} value={i}>{i}</Select.Option>)}
                </Select>
              </Form.Item>  */}
              <Form.Item
              name="rooms"
              label="Rooms"
              required={true}
              rules={[{required: true, message: "required"}]}
            >
              <Select style={{minWidth: '100px'}}>
                {option.map((e,i)=><Select.Option key={i} value={i}>{i}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item
              name="extrabed"
              label="Extra Bed"
              required={true}
              rules={[{required: true, message: "required"}]}
            >
              <Select style={{minWidth: '100px'}}>
                {option.map((e,i)=><Select.Option key={i} value={i}>{i}</Select.Option>)}
                </Select>
              </Form.Item>
          </Form>
        </Modal>
             </>
             
  );
};


