import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, InputNumber, Modal, Space, Switch, Table, Tag } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    DownloadOutlined,
    ClearOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import {
    SyncOutlined
  } from '@ant-design/icons';
import { DataTable } from '../common/DataTable';
import { itineraryQuotationProductSync } from './slice-itineraryquotation';
import { formatDateInTimeZone } from '../utils/CommonFn';
import { useForm } from 'antd/es/form/Form';



export const ItineraryProduct = () => {

  const {syncprodctloading}= useSelector((state:any)=>state.itineraryquotation)
  const dispatch = useDispatch();
  const datatableref = useRef();
  const [uistate, setState] = useState({openAdd: false})

    return (<>
      
        <DataTable
            title="Itinerary Products" 
            key="id"  
            extra={<><Button type='primary' onClick={()=>dispatch(itineraryQuotationProductSync()).then(()=>(datatableref.current as any)?.refresh())} loading={syncprodctloading}> <SyncOutlined />  Sync Product Data  </Button>
            
            </>}
            componentuniquekey="itinerarylist" 
            lazyPagination={false}
            buildApiUrl={(pageNumber, pagesize)=>`itinerary/productList?pageSize=${pagesize}&pageNo=${pageNumber}`} >
              <Table.Column dataIndex="productName" title="Product Name"/>
              <Table.Column dataIndex="price" title="Cost"/>
              <Table.Column  title="Created Date" render={(e:any)=>formatDateInTimeZone(e.created)}/>
              <Table.Column title="Updated Date" render={(e:any)=>formatDateInTimeZone(e.updated)}/>
             </DataTable> 
        </>);
}






