import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'
import moment from 'moment'
import { act } from 'react-dom/test-utils'


// First, create the thunk
// Define a type for the slice state
export const itineraryQuotationProductSearch = ThunkHttpClient.get('itineraryquotation/search',
    (data) => ("itinerary/productList?pageSize=1&pageNo=1") )

    

export const itineraryQuotationProductSync = ThunkHttpClient.get('itineraryquotation/sysncsearch',
    (data) => ("itinerary/syncItineraryProducts") )

export const itineraryFetchCustomerByEmail = ThunkHttpClient.get('itineraryquotation/itineraryFetchCustomerByEmail',
    (data) => ("itinerary/searchCustomer?email="+data.email) )

export const itineraryFetchHotelByName = ThunkHttpClient.get('itineraryquotation/itineraryFetchHotelByName',
    (data) => ("itinerary/searchHotel?name="+data))

export const itineraryPreview = ThunkHttpClient.get('itineraryquotation/itineraryFetchHotelByName',
    (data) => ("itinerary/previewQuotation?quotation_id="+data))

export const itinerarySaveHotel = ThunkHttpClient.post('itineraryquotation/itinerarySaveHotel',
    (data) => ({ url: "itinerary/saveHotel", body:  data}))

    export const itinerarySaveQuotationDetail = ThunkHttpClient.post('itineraryquotation/saveQuotation',
    ({customerid, sendToEmail, body}) => { return { url: "itinerary/saveQuotation?customerId="+customerid+"&sendToEmail="+sendToEmail, body:  body} })

    export const itinerarySaveCustomerDetail = ThunkHttpClient.post('itineraryquotation/savitinerarySaveCustomerDetaileQuotation',
    (body) => { return { url: "itinerary/saveCustomer", body:  body} })

    export const itinerarysendQuotationToEmail = ThunkHttpClient.get('itineraryquotation/savitinerarysendQuotationToEmaileQuotation',
    (body) => "itinerary/sendQuotationToEmail?quotation_id="+body)

    export const itineraryDeleteProduct = ThunkHttpClient.delete('itineraryquotation/savitinerarysendQuotationToProductDelete',
    (id) => "itinerary/deleteHotel?id="+id)

    export const itineraryDeleteHotel = ThunkHttpClient.delete('itineraryquotation/savitinerarysendQuotationToHotelDeete',
    (id) => "itinerary/deleteProduct?id="+id)

    export const saveProductOfIntenotryproduct = ThunkHttpClient.post('itineraryquotation/saveProductOfIntenotryproduct',
    (data) => { return { url: "itinerary/saveProduct", body:  {productName: data.productname, price: data.cost}} })  
    
    

// Define the initial state using that type
const initialState: any = {
    searchloading: false,
    searchdata: [],
    quotationemailsetnloading : 0,
    hotelloading: false,
    hoteldata: [],
    uotationuistate: {serachProductPopup: false, 
        searchHotelModal: false, editId: "", transferType: '', visaprice: 0, 
        visaenable: true,
        tourenable: true, 
        hotelenable: true,
        total: 0,
        discount: 0,
        vat: 0,
        visaname: 'Dubai Tour Visa',  customer: {id: 0}},
    quotation: {     
        customerdetail: {}, 
        quotationInfo: {},
        products: [], 
        hotels: [], 
        visa: {}
    }

}

export const itineraryquotation = createSlice({
    name: 'itineraryquotation',
    initialState,
    reducers: {

        resetquota: (state,action) => {
            state.uotationuistate = {serachProductPopup: false, 
                searchHotelModal: false, editId: "", transferType: '', visaprice: 0, 
                visaenable: true,
                tourenable: true, 
                hotelenable: true,
                total: 0,
                discount: 0,
                vat: 0,
                visaname: 'Dubai Tour Visa',  customer: {id: 0}};
            state.quotation = {     
                customerdetail: {}, 
                quotationInfo: {},
                products: [], 
                hotels: [], 
                visa: {}
            }
        },
        addProductToQuotation: (state, action) => {
            state.quotation.products = [...state.quotation.products, ...action.payload]
        },
        addHotelToQuotation : (state, action) => {
            state.quotation.hotels = [...state.quotation.hotels, ...action.payload]
        },
        updateDraftQuotation: (state, action) => {
            state.quotation=  action.payload
        },

        updateDraftQuotationUiState: (state, action) => {
            
            state.uotationuistate =  action.payload
        },

        createDraftQuotationFromData: (state, action) => {
            try {
            var q = JSON.parse(action.payload.quotationDetails);
            state.quotation.visa.visaenable = q.visainfo.visaenable;
            state.quotation.visa.visaprice = q.visainfo.visaprice;
            state.quotation.products = q.product;
            state.quotation.hotels = q.hotels;
            state.quotation.flights = q.flights;
            state.quotation.quotationInfo = q.quotationInfo;
            if(state.quotation.hotels.length>0) {
                var h = state.quotation.hotels[0];
                state.quotation.quotationInfo = {
                    ...q.quotationInfo,
                    rooms: h.rooms,
                    extrabed: h.extrabed, 
                    nights: h.nights
                }
            }

            state.uotationuistate =  {
                serachProductPopup: false, 
                searchHotelModal: false, editId: "", transferType: '', visaprice: q.visainfo.visaprice, 
                visaenable: true,
                tourenable: true, 
                hotelenable: true,
                flightenable: true,
                total: 0,
                discount: 0,
                vat: 0,
                visaname:  q.visainfo.visaname,  customer: {id: 0}}
            } catch(e){
                console.error(e)
            }
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(itineraryQuotationProductSearch.fulfilled, (state, action) => {
            state.searchloading = false;
            state.searchdata = action.payload.data
        })
        builder.addCase(itineraryQuotationProductSearch.pending, (state, action) => {
            state.searchdata=[];
            state.searchloading = true;
        })
        builder.addCase(itineraryQuotationProductSearch.rejected, (state, action) => {
            state.searchloading = false;
        })


        //
        builder.addCase(itineraryQuotationProductSync.fulfilled, (state, action) => {
            state.syncprodctloading = false;
        })
        builder.addCase(itineraryQuotationProductSync.pending, (state, action) => {
            state.searchdata=[];
            state.syncprodctloading = true;
        })
        builder.addCase(itineraryQuotationProductSync.rejected, (state, action) => {
            state.syncprodctloading = false;
        })


        //
        builder.addCase(itineraryFetchCustomerByEmail.fulfilled, (state, action) => {
            state.searchByEmailCustomerLoading = false;
        })
        builder.addCase(itineraryFetchCustomerByEmail.pending, (state, action) => {
            state.searchByEmailCustomerLoading = true;
        })
        builder.addCase(itineraryFetchCustomerByEmail.rejected, (state, action) => {
            state.searchByEmailCustomerLoading = false;
        })

         //
         builder.addCase(itineraryFetchHotelByName.fulfilled, (state, action) => {
            state.hotelloading = false;
            state.hoteldata = action.payload.data;
            
        })
        builder.addCase(itineraryFetchHotelByName.pending, (state, action) => {
            state.hotelloading = true;
        })
        builder.addCase(itineraryFetchHotelByName.rejected, (state, action) => {
            state.hotelloading = false;
        })


         //
         builder.addCase(itinerarySaveHotel.fulfilled, (state, action) => {
            state.savehotelloading = false;
            
        })
        builder.addCase(itinerarySaveHotel.pending, (state, action) => {
            state.savehotelloading = true;
        })
        builder.addCase(itinerarySaveHotel.rejected, (state, action) => {
            state.savehotelloading = false;
        })



        builder.addCase(itinerarySaveQuotationDetail.fulfilled, (state, action) => {
            state.saveQuotationdetailloading = false;
            
        })
        builder.addCase(itinerarySaveQuotationDetail.pending, (state, action) => {
            state.saveQuotationdetailloading = true;
        })
        builder.addCase(itinerarySaveQuotationDetail.rejected, (state, action) => {
            state.saveQuotationdetailloading = false;
        })

        builder.addCase(itinerarysendQuotationToEmail.fulfilled, (state, action) => {
            state.quotationemailsetnloading = 0;     
        })
        builder.addCase(itinerarysendQuotationToEmail.pending, (state, action) => {
            state.quotationemailsetnloading = action.meta.arg;
        })
        builder.addCase(itinerarysendQuotationToEmail.rejected, (state, action) => {
            state.quotationemailsetnloading = 0;
        })

       

        //
        builder.addCase(itineraryDeleteProduct.fulfilled, (state, action) => {
            state.quotaionproductdeleteeloading = 0;
            popMessage({type:'info', content: 'Product deleted successfully'})     
        })
        builder.addCase(itineraryDeleteProduct.pending, (state, action) => {
            state.quotaionproductdeleteeloading = action.meta.arg;
        })
        builder.addCase(itineraryDeleteProduct.rejected, (state, action) => {
            state.quotaionproductdeleteeloading = 0;
            
        })

        //
        builder.addCase(itineraryDeleteHotel.fulfilled, (state, action) => {
            state.quotaionproducthoteldeleteloading = 0;   
            popMessage({type:'info', content: 'Hotel deleted successfully'})   
              
        })
        builder.addCase(itineraryDeleteHotel.pending, (state, action) => {
            state.quotaionproducthoteldeleteloading = action.meta.arg;
        })
        builder.addCase(itineraryDeleteHotel.rejected, (state, action) => {
               
            state.quotaionproducthoteldeleteloading = 0;
        })


        //
        builder.addCase(saveProductOfIntenotryproduct.fulfilled, (state, action) => {
            state.quotaionproductaddeloading = 0;  
           popMessage({type:'info', content: 'Product added successfully'})  
               
        })
        builder.addCase(saveProductOfIntenotryproduct.pending, (state, action) => {
            state.quotaionproductaddeloading = action.meta.arg;
        })
        builder.addCase(saveProductOfIntenotryproduct.rejected, (state, action) => {
            state.quotaionproductaddeloading = 0;
        })



        
    },
})


export const { addProductToQuotation, updateDraftQuotation , updateDraftQuotationUiState, resetquota, addHotelToQuotation, createDraftQuotationFromData } = itineraryquotation.actions
export default itineraryquotation.reducer