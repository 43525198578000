import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'


// First, create the thunk
export const datatablelistApiFunction = ThunkHttpClient.get('Datatable/list', ({url}) =>  url)


// Define the initial state using that type
const initialState: any = {
}

export const DatatableSlice = createSlice({
    name: 'Datatable',
    initialState,
    reducers: {
        
    },

    extraReducers: (builder) => {
       
        builder.addCase(datatablelistApiFunction.fulfilled, (state, action) => {
            const component = action.meta.arg.componentuniquekey;
            state[component] = state[component] || {}
            state[component].loading = false;
            state[component].data = action.payload.data
        })
        builder.addCase(datatablelistApiFunction.pending, (state, action) => {
            const component = action.meta.arg.componentuniquekey;
            state[component] = state[component] || {}
            state[component].loading = true;
            state[component].data = []
        })
        builder.addCase(datatablelistApiFunction.rejected, (state, action) => {
            const component = action.meta.arg.componentuniquekey;
            state[component] = state[component] || {}
            state[component].loading = false;
        })
    },
})

export default DatatableSlice.reducer