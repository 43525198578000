import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    
    CloseOutlined,
    DeleteOutlined,
    ClearOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { itineraryQuotationProductSearch, addProductToQuotation, itineraryFetchCustomerByEmail, updateDraftQuotation, itineraryFetchHotelByName, addHotelToQuotation, itinerarySaveHotel, itinerarySaveQuotationDetail, itinerarySaveCustomerDetail, updateDraftQuotationUiState, resetquota, itineraryDeleteHotel, itineraryDeleteProduct, saveProductOfIntenotryproduct }
 from '../slice-itineraryquotation';
import { key } from 'localforage';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { stat } from 'fs';


const AddProduct = ({onOk, open, onCancel}: any) => {
    const {quotation, quotaionproductaddeloading}= useSelector((state:any)=>state.itineraryquotation)
    const [customerfrom] = useForm();
    const dispatch = useDispatch();
   
  
    return <Modal 
          confirmLoading={quotaionproductaddeloading}
         title="Customer Detail"
         cancelText="Cancle & Return"
        onCancel={onCancel}
         onOk={()=>{
          customerfrom.validateFields().then(()=>{
          customerfrom.submit();
          onOk()
          }).catch(()=>{})
         }}
         open={open}>
         <Form
        
        name="customeproductrmodalformsdfsd"
        labelCol={ { span: 8 }}
         wrapperCol={{ span: 16 }}
         form={customerfrom}
         initialValues={{child: 0}}
         onFinish={(data)=>{
          dispatch(saveProductOfIntenotryproduct(data)).then(()=>{
            onOk()
          });       
        }}
        className="login-form"
        style={{ maxWidth: 'none', padding: '5px' }}
      >     
  
  <Form.Item
          name="id"
        >
        <Input  style={{display: 'none'}}/>
          </Form.Item> 
  
          <Form.Item
          name="productname"
          required={true}
          rules={[ {required: true, message: 'Enter Product name'}]}
          label="Product Name"
        >
        <Input  />
          </Form.Item> 
          
  
          <Form.Item
          name="cost"
          label="Cost of Tour"
          required={true}
          rules={[{required: true, message: 'Enter valid price'}]}
        >
        <InputNumber />
          </Form.Item>
      </Form>
         </Modal>
  }
  
  





export  const SearchProductModal = ({open, onCancel} : any) => {
  
    const [state, setState] = useState({selectedRows: [] as any, count: 0, search : '', openAdd: false});
    const {searchdata, searchloading, quotation, quotaionproducthoteldeleteloading}= useSelector((state:any)=>state.itineraryquotation)
    const dispatch = useDispatch();
    
    const [form] = Form.useForm();
   
  
    const rowSelection = {
      type: 'checkbox',
      onSelect: (selectedRowKeys: any) => {
        if(state.selectedRows.indexOf(selectedRowKeys.id)<0) {
          setState((statea)=> ({ ...statea, selectedRows: [...statea.selectedRows, selectedRowKeys.id]}))
         } else {
          setState((statea)=> {
            const n = [...state.selectedRows];
                const index = state.selectedRows.indexOf(selectedRowKeys.id);
              if (index > -1) {
                 n.splice(index, 1); 
               }
            return { ...statea, selectedRows: n}
          
         })
         }
      },
      onSelectNone: () => {
        setState((statea)=> ({ ...statea, selectedRows: []}))
      },
      onSelectAll: (selectedRowKeys: any) => {
        if(selectedRowKeys) {
        setState((statea)=> ({ ...statea, selectedRows: searchdata.map((a:any)=>a.id)}))
        } else {
          setState((statea)=> ({ ...statea, selectedRows: []}))
        }
      },
    };
    
  
    const data = searchdata.filter((v:any)=> v.productName.toUpperCase().includes(state.search.toUpperCase()))
  
  
    return (
      <>
      <Modal open={open} width={900} 
      style={{ zIndex: 99999}}
          okText="Add to itenerary"
          okButtonProps={{disabled: state.selectedRows.length === 0}}
          onOk={()=>{
            dispatch(addProductToQuotation(searchdata.filter((e:any)=>state.selectedRows.indexOf(e.id)>=0).map(((e:any)=>({...e,'transferType': 'Shared', adult: quotation.quotationInfo.adult, child: quotation.quotationInfo.child, id: e.id+'_'+new Date().getTime()})))));
            onCancel()
          }}
          onCancel={onCancel}>
          
  
          {state.openAdd ? <AddProduct open={state.openAdd} onCancel={()=>setState({...state, openAdd: false})} onOk={()=>{
      setState({...state, openAdd: false}) ;
      dispatch(itineraryQuotationProductSearch(''))
      
      }} /> : null}
  
      <Form
        name="itinaroyqutationsearch"
        layout={'inline'}
        onValuesChange={e=>setState({...state, ...e})}
        form={form}
        initialValues={{  }}
        className="login-form"
        style={{ maxWidth: 'none', padding: '5px' }}
      >     
      <Form.Item
          name="search"
          label="Enter product name"
        >
        <Input />
          </Form.Item> 
          <Button type='primary' onClick={()=>setState({...state, openAdd: true})}>Create New Product</Button>
       {/* <Form.Item name="date" label="Date">
          <DatePicker placeholder='Select Date' />
        </Form.Item>
        <Form.Item name="expirebefore" label="Expire Before">
          <TimePicker  format={'HH:mm'} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit"  className="login-form-button" loading={searchloading}>
            <SearchOutlined />
            Search
          </Button>
        </Form.Item> */}
      </Form>
      
             
      <div className='dataTableMobileView'>
        <Table dataSource={data || []} loading={searchloading} rowSelection={rowSelection as any}  rowKey={'id'}>
          <Table.Column dataIndex="id" title="#"/>
          <Table.Column dataIndex="productName" title="Product Name"/>
          <Table.Column title="Delete" render={(e:any)=>   e.productKey ? <Button loading={e.id === quotaionproducthoteldeleteloading} onClick={() => dispatch(itineraryDeleteProduct(e.id)).then(()=>{
                dispatch(itineraryQuotationProductSearch(''))
          })}>
                  <DeleteOutlined />
                   </Button>: <></>} />
        </Table> 
      </div>
               </Modal>
               </>
      
    );
  };