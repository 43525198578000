import React, { useEffect } from 'react';
import { LoginC } from './component/security/Login';
import { ForgotPass } from './component/security/ForgotPass';
import { ResetPass } from './component/security/ResetPass';
import { Home } from './component/Home';
import { Outlet, isRouteErrorResponse, useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { TicketInvetoryUpload } from './component/ticketinventory/TicketInvetoryUpload';
import { TicketInventory } from './component/ticketinventory/TicketInventory';
import { Button, Result } from 'antd';
import { Master } from './component/common/Master';
import { TicketInventoryDownload } from './component/ticketinventory/TicketInventoryDownload';
import { TicketInventoryProduct } from './component/ticketinventory/TicketInventoryProduct';
import { TicketListForProduct } from './component/ticketinventory/TicketListForProductwise';
import { ItineraryProduct } from './component/itinerary/ItineraryProduct';
import { QuotationList } from './component/itinerary/QuotationList';
import { ItineraryQuotation } from './component/itinerary/Quotation';
import { IteneraryDashBoard } from './component/ticketinventory/Dashboard';
import { TicketInventoryUploadList } from './component/ticketinventory/TicketInventoryUploadList';
import { routesvalues } from './moduleconfiguration';


function RootBoundary() {
  const error = useRouteError();
  const nav = useNavigate();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={e=>nav('/home')}>Back Home</Button>}
      />;
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return <div>Something went wrong</div>;
}


const RedirectFlow = () => {
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      nav('/home');
    }
  }, [])
  return <Outlet />;
}

export const routeDetail = [
  {
    path: "/",
    element: <RedirectFlow />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: "/home",
        element: <Home />,
        children: [
           ...routesvalues(),
          {
            path: "/home/ticketinventory/list",
            element: <TicketInventoryUploadList />,
          },
          {
              path: "/home/upload",
              element: <TicketInvetoryUpload />,
            },  
          {
            path: "/home/ticketinventory/productlist",
            element: <TicketInventoryProduct />,
          },
          {
            path: "/home/ticketinventory/download",
            element: <TicketInventoryDownload />,
          },
          {
            path: "/home/",
            element: <IteneraryDashBoard />,
          },
          {
            path: "/home/master",
            element: <Master />,
          },
          {
            path: "/home/itinerary/list",
            element: <ItineraryProduct />,
          },
          {
            path: "/home/itinerary/quotation",
            element: <ItineraryQuotation />,
          },
          {
            path: "/home/itinerary/quotationlist",
            element: <QuotationList />,
          },
          {
            path: "/home/list",
            element: <TicketListForProduct />,
          },
        ]
      },
    ],
  },
  {
    path: "/login",
    element: <LoginC />,
  },
  {
    path: "/forgot",
    element: <ForgotPass />,
  },
  {
    path: "/reset",
    element: <ResetPass />,
  },
  {
    path: "/reset1",
    element: <ResetPass />,
  },
]