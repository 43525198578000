import path from "path";
import { lazy } from "react";

const routerNReducer =  [

    // User Management User Component
    {
      path: "/home/user",
      async lazy() {
        let {User} = await import("./component/usermanagement/users/Users");
        return { Component: User };
      },
      reducer: {
        name: 'user',
        reducer: () => import('./component/usermanagement/users/slice-user').then((module:any) => module.reducer) 
      },
    },

    // User Management Company Component

     {
      path: "/home/company",
      async lazy() {
        let {Company} = await import("./component/usermanagement/company/Company");
        return { Component: Company };
      },
      reducer: {
        name: 'company',
        reducer: () => import('./component/usermanagement/company/slice-company').then((module:any) => module.reducer) 
      },
    }
  ] as any[];


  let reducerDetail = {} as any;

  const routesDetail:any = (routerDetails:any[]) => {
    return routerDetails.map((route:any) => {

        if(route.reducer.name)
        reducerDetail[route.reducer.name] = route.reducer.reducer;
        return {
            path: route.path,
            lazy: route.lazy,
            children: route.children ? routesDetail(route.children) : [],
        }
       });
  }


  export const routesvalues =  () => {
   return routesDetail(routerNReducer) || []; 
  }

  export const reducers = ()=> {
    routesDetail(routerNReducer); 
    return reducerDetail;
  }

